export const MODALS = [
  'AboutInviteCodes',
  'AchOverview',
  'AddCreditCardMethod',
  'Advocate',
  'AssetLevel',
  'CardCcvConfirmation',
  'CardLadderHelp',
  'ConfirmEmail',
  'ConfirmLogout',
  'CountryNotAllowed',
  'Custody',
  'DepositUsdc',
  'DepositUsdcConfirmation',
  'DisableTwoFactorAuthentication',
  'EmailNotVerified',
  'EmailTokenExpired',
  'EnableTwoFactorAuthentication',
  'ForgotPassword',
  'GlobalSearch',
  'KycAddress',
  'KycBasicInfo',
  'KycCountry',
  'KycDocuments',
  'KycProofOfAddress',
  'KycProofOfBank',
  'KycSelectFracPack',
  'KycSSN',
  'KycStatus',
  'MethodRemovalConfirmation',
  'MobileMenu',
  'OpenPackModal',
  'PaymentInterface',
  'ResetPassword',
  'RewardDisclaimer',
  'RewardOrderConfirmed',
  'RewardReviewOrder',
  'Share',
  'SubmitFeedback',
  'TwoFactorAuthenticationBackupCode',
  'TwoFactorAuthenticationDisabled',
  'TwoFactorAuthenticationEmailVerification',
  'TwoFactorAuthenticationEnabled',
  'TwoFactorAuthenticationReset',
  'TwoFactorAuthenticationVerification',
  'UserAgreement',
  'VerifyAuthentication',
  'Video',
  'WalletAddTransferMethod',
  'WalletAddWireTransferDetails',
  'WalletResendWithdrawalConfirmation',
  'WalletWireTransferDetails',
  'WebRequiresUserFlag',
  'WithdrawalConfirmation',
  'WithdrawUsdc',
  'YourSecureKey',
 
  // Require mock data
  // 'AssetViewer',
  // 'BuyDropConfirmation',
  // 'BuyPlayer',
  // 'BuyPlayerConfirmation',
  // 'Card3ds',
  // 'DropConfirmed',
  // 'FracPack',
  // 'Onboard',
  // 'PackOpen',
  // 'ReviewFracpackModal',
  // 'RewardGemsEarned',
  // 'RewardGemsKyc',
  // 'SellPlayer',
  // 'SellPlayerConfirmation',
  // 'WalletTransferLimit',
  // 'WalletTransferSuccess',
];
