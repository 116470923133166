<template>
  <div class="dev-page" v-if="showDev">
    
    <!-- Modals -->
    <div class="text-xl33 font-bold mb-s12">Modals</div>
    <div class="flex w-full justify-center pb-s32">
      <select  @change="openDemoModal($event.target.value)">
        <option>Select an modal to open</option>
        <option v-for="modal in modalsList" :key="modal">
          {{modal}}
        </option>
      </select>
    </div>

    <!-- Skeleton Loading -->
    <div class="text-xl33 font-bold">Skeleton Loading</div>
    <div class="flex mt-4 w-full">
      <div class="flex w-1/3">
        <div>
          <SkeletonLoading type="circle" height="100px" />
        </div>
        <div class="flex flex-col justify-center items-left ml-4 w-full">
          <SkeletonLoading class="mb-2" height="40px" />
          <SkeletonLoading height="40px" />
        </div>
      </div>

      <div class="flex ml-8 w-1/3">
        <div class="w-1/2">
          <SkeletonLoading height="100px" />
        </div>
        <div class="flex flex-col justify-center items-left ml-4 w-1/2">
          <SkeletonLoading class="mb-2" borderRadius="10px" height="40px" />
          <SkeletonLoading borderRadius="10px" height="40px" />
        </div>
      </div>

      <div class="flex ml-8 w-1/3">
        <div>
          <SkeletonLoading type="circle" height="100px" />
        </div>
        <div class="flex flex-col justify-center items-left ml-4 w-full">
          <SkeletonLoading class="mb-2" borderRadius="10px" height="40px" />
          <SkeletonLoading borderRadius="10px" height="40px" />
        </div>
      </div>
    </div>

    <div class="flex w-full text-xl33 font-bold mt-18 justify-center mb-4">
      Socure/Circle
    </div>
    <div class="flex justify-center flex-wrap gap-4">
      <ButtonV2
        version="tertiary"
        label="Add Wire Transfer Details"
        @onClick="showModal('WalletAddWireTransferDetails')"
      />
      <ButtonV2
        version="tertiary"
        label="Show Wire Transfer Details"
        @onClick="
          showModal(
            'WalletWireTransferDetails',
            { wireId: '2e9faafd-3a1c-4148-9b8d-954b6c35402a' },
            {},
          )
        "
      />
      <ButtonV2
        version="tertiary"
        @onClick="showModal('AddCreditCardMethod')"
        label="AddCreditCardMethod"
      />
      <ButtonV2
        version="tertiary"
        @onClick="showModal('AchOverview')"
        label="AchOverview"
      />
      <ButtonV2
        @onClick="showModal('KycBasicInfo')"
        label="KycBasicInfo"
        version="tertiary"
      />
      <ButtonV2
        version="tertiary"
        label="KycStatus"
        @onClick="showModal('KycStatus')"
      />
      <ButtonV2
        version="tertiary"
        label="Open FracPack"
        @onClick="openFracPack"
      />
    </div>

    <!-- Asset Viewer -->
    <div class="text-xl33 font-bold mt-18">Asset Viewer</div>
    <div class="flex justify-center mt-4">
      <div class="relative">
        <ImageResize
          alt="asset_image"
          src="https://img.dibbscdn.com/21bbcbcf-5abb-41de-87d7-eab3e6370156"
          class="h-80"
          type="trade_widget"
        />
        <div
          @click="
            openAssetViewer(
              'https://img.dibbscdn.com/21bbcbcf-5abb-41de-87d7-eab3e6370156',
              'https://img.dibbscdn.com/06864a38-443d-4e42-8dca-07f72eb6674d',
            )
          "
          class="zoom-icon-container"
        >
          <font-awesome-icon icon="search-plus" />
        </div>
      </div>
    </div>

    <!-- New Buttons -->
    <div class="text-xl33 font-bold mt-18">New Buttons</div>
    <div>
      <div class="flex justify-between mt-6">
        <ButtonV2
          label="Primary Button"
          version="primary"
          @onClick="buttonClickedExample"
        />
        <ButtonV2
          label="Secondary Button"
          version="secondary"
          @onClick="buttonClickedExample"
        />
        <ButtonV2
          label="Tertiary Button"
          version="tertiary"
          @onClick="buttonClickedExample"
        />
        <ButtonV2
          label="Destructive Button"
          version="destructive"
          @onClick="buttonClickedExample"
        />
      </div>
      <div class="flex justify-between mt-6">
        <ButtonV2 inactive label="Primary Button Inactive" version="primary" />
        <ButtonV2
          inactive
          label="Secondary Button Inactive"
          version="secondary"
        />
        <ButtonV2
          inactive
          label="Tertiary Button Inactive"
          version="tertiary"
        />
        <ButtonV2
          inactive
          label="Destructive Button Inactive"
          version="destructive"
        />
      </div>
      <div class="flex justify-between mt-6">
        <ButtonV2
          size="medium"
          label="Primary Medium Button"
          version="primary"
          @onClick="buttonClickedExample"
        />
        <ButtonV2
          size="medium"
          label="Secondary Medium Button"
          version="secondary"
          @onClick="buttonClickedExample"
        />
        <ButtonV2
          size="medium"
          label="Destructive Medium Button"
          version="destructive"
          @onClick="buttonClickedExample"
        />
      </div>
      <div class="flex justify-between mt-6">
        <ButtonV2
          size="medium"
          inactive
          label="Primary Medium Button Inactive"
          version="primary"
        />
        <ButtonV2
          size="medium"
          inactive
          label="Secondary Medium Button Inactive"
          version="secondary"
        />
        <ButtonV2
          size="medium"
          inactive
          label="Destructive Medium Button Inactive"
          version="destructive"
        />
      </div>
      <div class="mt-6">
        <ButtonV2
          :wide="true"
          label="Primary Button Wide"
          version="primary"
          @onClick="buttonClickedExample"
        />
      </div>
    </div>

    <!-- Legacy Buttons -->
    <div class="text-xl33 font-bold mt-18">Legacy Buttons</div>
    <div>
      <div class="flex justify-between mt-6">
        <Button label="Button v1" version="v1" />
        <Button
          label="Button v1"
          version="v1"
          icon="IconArrowDown"
          iconClasses="w-4 h-4 mr-1-5"
        />
        <Button label="Button v1" version="v1" :isLoading="true" />
        <Button label="Button v1" version="v1" :isDisabled="true" />
      </div>
      <div class="flex justify-between mt-6">
        <Button label="Button v2" version="v2" customClasses="px-6 py-2" />
        <Button
          label="Button v2"
          version="v2"
          customClasses="px-6 py-2"
          icon="IconArrowUp"
          iconClasses="w-4 h-4 mr-1-5"
        />
        <Button
          label="Button v2"
          version="v2"
          customClasses="px-6 py-2"
          :isLoading="true"
        />
        <Button
          label="Button v2"
          version="v2"
          customClasses="px-6 py-2"
          :isDisabled="true"
        />
      </div>
      <div class="flex justify-between mt-6">
        <Button label="Button v3" version="v3" customClasses="px-6 py-2" />
        <Button
          label="Button v3"
          version="v3"
          customClasses="px-6 py-2"
          icon="IconPlayAlt"
          iconClasses="w-4 h-4 mr-1-5 stroke-current"
        />
        <Button
          label="Button v3"
          version="v3"
          customClasses="px-6 py-2"
          :isLoading="true"
        />
        <Button
          label="Button v3"
          version="v3"
          customClasses="px-6 py-2"
          :isDisabled="true"
        />
      </div>
      <div class="flex justify-between mt-6">
        <Button
          label="Button v4 primary"
          version="v4-primary"
          customClasses="px-6 py-2"
        />
        <Button
          label="Button v4 primary"
          version="v4-primary"
          customClasses="px-6 py-2"
          icon="IconShare"
          iconClasses="w-4 h-4 mr-1-5"
        />
        <Button
          label="Button v4 primary"
          version="v4-primary"
          customClasses="px-6 py-2"
          :isLoading="true"
        />
        <Button
          label="Button v4 primary"
          version="v4-primary"
          customClasses="px-6 py-2"
          :isDisabled="true"
        />
      </div>
      <div class="flex justify-between mt-6">
        <Button
          label="Button v4 primary small"
          version="v4-primary-small"
          customClasses="px-6 py-2"
        />
        <Button
          label="Button v4 primary small"
          version="v4-primary-small"
          customClasses="px-6 py-2"
          icon="IconTriangle"
          iconClasses="w-4 h-4 mr-1-5"
        />
        <Button
          label="Button v4 primary small"
          version="v4-primary-small"
          customClasses="px-6 py-2"
          :isLoading="true"
        />
        <Button
          label="Button v4 primary small"
          version="v4-primary-small"
          customClasses="px-6 py-2"
          :isDisabled="true"
        />
      </div>
      <div class="flex justify-between mt-6">
        <Button
          label="Button v4 secondary"
          version="v4-secondary"
          customClasses="px-6 py-2"
        />
        <Button
          label="Button v4 secondary"
          version="v4-secondary"
          customClasses="px-6 py-2"
          icon="IconClose"
          iconClasses="w-4 h-4 mr-1-5"
        />
        <Button
          label="Button v4 secondary"
          version="v4-secondary"
          customClasses="px-6 py-2"
          :isLoading="true"
        />
        <Button
          label="Button v4 secondary"
          version="v4-secondary"
          customClasses="px-6 py-2"
          :isDisabled="true"
        />
      </div>
      <div class="flex justify-between mt-6">
        <Button
          label="Button v4 secondary small"
          version="v4-secondary-small"
          customClasses="px-6 py-2"
        />
        <Button
          label="Button v4 secondary small"
          version="v4-secondary-small"
          customClasses="px-6 py-2"
          icon="IconHistory"
          iconClasses="w-4 h-4 mr-1-5"
        />
        <Button
          label="Button v4 secondary small"
          version="v4-secondary-small"
          customClasses="px-6 py-2"
          :isLoading="true"
        />
        <Button
          label="Button v4 secondary small"
          version="v4-secondary-small"
          customClasses="px-6 py-2"
          :isDisabled="true"
        />
      </div>
      <div class="flex justify-between mt-6">
        <Button
          label="Button v4 tertiary"
          version="v4-tertiary"
          customClasses="px-6 py-2"
        />
        <Button
          label="Button v4 tertiary"
          version="v4-tertiary"
          customClasses="px-6 py-2"
          icon="IconPlayAlt"
          iconClasses="w-4 h-4 mr-1-5 stroke-current"
        />
        <Button
          label="Button v4 tertiary"
          version="v4-tertiary"
          customClasses="px-6 py-2"
          :isLoading="true"
        />
        <Button
          label="Button v4 tertiary"
          version="v4-tertiary"
          customClasses="px-6 py-2"
          :isDisabled="true"
        />
      </div>
      <div class="flex justify-between mt-6">
        <Button
          label="Button v4 tertiary small"
          version="v4-tertiary-small"
          customClasses="px-6 py-2"
        />
        <Button
          label="Button v4 tertiary small"
          version="v4-tertiary-small"
          customClasses="px-6 py-2"
          icon="IconPlayAlt"
          iconClasses="w-4 h-4 mr-1-5 stroke-current"
        />
        <Button
          label="Button v4 tertiary small"
          version="v4-tertiary-small"
          customClasses="px-6 py-2"
          :isLoading="true"
        />
        <Button
          label="Button v4 tertiary small"
          version="v4-tertiary-small"
          customClasses="px-6 py-2"
          :isDisabled="true"
        />
      </div>
      <div class="flex justify-between mt-6">
        <Button label="Button v5" version="v5" customClasses="px-6 py-2" />
        <Button
          label="Button v5"
          version="v5"
          customClasses="px-6 py-2"
          icon="IconPlayAlt"
          iconClasses="w-4 h-4 mr-1-5 stroke-current"
        />
        <Button
          label="Button v5"
          version="v5"
          customClasses="px-6 py-2"
          :isLoading="true"
        />
        <Button
          label="Button v5"
          version="v5"
          customClasses="px-6 py-2"
          :isDisabled="true"
        />
      </div>
      <div class="flex justify-between mt-6">
        <Button label="Button v6" version="v6" customClasses="px-6" />
        <Button
          label="Button v6"
          version="v6"
          customClasses="px-6"
          icon="IconPlayAlt"
          iconClasses="w-4 h-4 mr-1-5 stroke-current"
        />
        <Button
          label="Button v6"
          version="v6"
          customClasses="px-6"
          :isLoading="true"
        />
        <Button
          label="Button v6"
          version="v6"
          customClasses="px-6"
          :isDisabled="true"
        />
      </div>
      <div class="flex justify-between mt-6">
        <Button label="Button v6 red" version="v6-red" customClasses="px-6" />
        <Button
          label="Button v6 red"
          version="v6-red"
          customClasses="px-6"
          icon="IconPlayAlt"
          iconClasses="w-4 h-4 mr-1-5 stroke-current"
        />
        <Button
          label="Button v6 red"
          version="v6-red"
          customClasses="px-6"
          :isLoading="true"
        />
        <Button
          label="Button v6 red"
          version="v6-red"
          customClasses="px-6"
          :isDisabled="true"
        />
      </div>
      <div class="flex justify-between mt-6">
        <Button label="Button v7" version="v7" customClasses="px-6" />
        <Button
          label="Button v7"
          version="v7"
          customClasses="px-6"
          icon="IconPlayAlt"
          iconClasses="w-4 h-4 mr-1-5 stroke-current"
        />
        <Button
          label="Button v7"
          version="v7"
          customClasses="px-6"
          :isLoading="true"
        />
        <Button
          label="Button v7"
          version="v7"
          customClasses="px-6"
          :isDisabled="true"
        />
      </div>
      <div class="flex justify-between mt-6">
        <Button label="Button v8 red" version="v8-red" customClasses="px-6" />
        <Button
          label="Button v8 red"
          version="v8-red"
          customClasses="px-6"
          icon="IconPlayAlt"
          iconClasses="w-4 h-4 mr-1-5 stroke-current"
        />
        <Button
          label="Button v8 red"
          version="v8-red"
          customClasses="px-6"
          :isLoading="true"
        />
        <Button
          label="Button v8 red"
          version="v8-red"
          customClasses="px-6"
          :isDisabled="true"
        />
      </div>
      <div class="flex justify-between mt-6">
        <Button
          label="Button v8 green"
          version="v8-green"
          customClasses="px-6"
        />
        <Button
          label="Button v8 green"
          version="v8-green"
          customClasses="px-6"
          icon="IconPlayAlt"
          iconClasses="w-4 h-4 mr-1-5 stroke-current"
        />
        <Button
          label="Button v8 green"
          version="v8-green"
          customClasses="px-6"
          :isLoading="true"
        />
        <Button
          label="Button v8 green"
          version="v8-green"
          customClasses="px-6"
          :isDisabled="true"
        />
      </div>
      <div class="flex justify-between mt-6">
        <Button label="Button v9" version="v9" customClasses="px-6" />
        <Button
          label="Button v9"
          version="v9"
          customClasses="px-6"
          icon="IconPlayAlt"
          iconClasses="w-4 h-4 mr-1-5 stroke-current"
        />
        <Button
          label="Button v9"
          version="v9"
          customClasses="px-6"
          :isLoading="true"
        />
        <Button
          label="Button v9"
          version="v9"
          customClasses="px-6"
          :isDisabled="true"
        />
      </div>
      <div class="flex justify-between mt-6">
        <Button label="Button v10" version="v10" customClasses="px-6" />
        <Button
          label="Button v10"
          version="v10"
          customClasses="px-6"
          icon="IconPlayAlt"
          iconClasses="w-4 h-4 mr-1-5 stroke-current"
        />
        <Button
          label="Button v10"
          version="v10"
          customClasses="px-6"
          :isLoading="true"
        />
        <Button
          label="Button v10"
          version="v10"
          customClasses="px-6"
          :isDisabled="true"
        />
      </div>
    </div>

    <!-- Info Panel -->
    <div class="text-xl33 font-bold mt-16">Info Panel</div>
    <div class="flex items-end justify-between">
      <InfoPanel title="Titulo" icon="check">
        Lorem ipsum dolor sit <a href="#">amet consectetur adipisicing</a> elit.
        Dolorum sunt, necessitatibus harum omnis suscipit fugit totam nostrum
        ipsa adipisci laboriosam ducimus rerum alias itaque facere labore a,
        pariatur, corrupti porro.
      </InfoPanel>
    </div>

    <!-- New Inputs -->
    <div class="text-xl33 font-bold mt-16">New Inputs</div>
    <div>
      <div class="flex justify-between items-start mt-6">
        <form class="dev-form w-full">
          <CustomInput v-model="input" placeholder="Primary input">
            Default Label
            <template v-slot:right-label> Right aligned label </template>
          </CustomInput>
        </form>
        <form class="dev-form w-full">
          <CustomInput v-model="input" placeholder="Primary input with icon">
            Default Label
            <template v-slot:icon>
              <IconSearch class="w-n-3xl h-n-3xl" />
            </template>
          </CustomInput>
        </form>
        <form class="dev-form w-full">
          <CustomInput
            v-model="input"
            placeholder="Primary input with error"
            :hasError="!!input"
          >
            Primary Input with error on input
            <template v-slot:error> Error message </template>
          </CustomInput>
        </form>
      </div>
      <div class="flex justify-between mt-s8">
        <form class="dev-form w-full">
          <CustomInput
            v-model="input"
            placeholder="Primary input without label"
          />
        </form>
        <form class="dev-form w-full">
          <CustomInput v-model="doubleInput" placeholder="$" filter="money">
            Dollar Input
          </CustomInput>
        </form>
        <form class="dev-form w-full">
          <CustomInput v-model="doubleInput" placeholder="%" filter="percent">
            Percentage Input
          </CustomInput>
        </form>
        <form class="dev-form w-full">
          <CustomInput v-model="doubleInput" placeholder="0.0" filter="double">
            Double Input
          </CustomInput>
        </form>
      </div>
    </div>

    <!-- Legacy Inputs -->
    <div class="text-xl33 font-bold mt-16">Legacy Inputs</div>
    <div>
      <div class="flex justify-between mt-6">
        <form class="dev-form">
          <input placeholder="Input v1" type="text" class="v1" />
        </form>
        <form class="dev-form">
          <input placeholder="Input v2" type="text" class="v2 px-4 py-1" />
        </form>
        <form class="dev-form">
          <input placeholder="Input v3" type="text" class="v3" />
        </form>
        <form class="dev-form">
          <input placeholder="Input v4" type="text" class="v4" />
        </form>
      </div>
      <div class="flex justify-between mt-8">
        <form class="dev-form">
          <input placeholder="Input v5" type="text" class="v5" />
        </form>
        <form class="dev-form">
          <input placeholder="Input v6" type="text" class="v6" />
        </form>
        <form class="dev-form">
          <input placeholder="Input v7" type="text" class="v7" />
        </form>
        <form class="dev-form">
          <input placeholder="Input v8" type="text" class="v8" />
        </form>
        <form class="dev-form">
          <input placeholder="Input v9" type="text" class="v9" />
        </form>
        <form class="dev-form">
          <input placeholder="Input v10" type="text" class="v10" />
        </form>
        <form class="dev-form">
          <input placeholder="Input v11" type="text" class="v11" />
        </form>
      </div>
    </div>

    <!-- OrderConfirmed -->
    <div class="text-xl33 font-bold mt-18">Order Confirmed</div>
    <div class="flex justify-between mt-6">
      <OrderConfirmed
        :orderConfirmed="buyConfirmed"
        title="trade.buy_confirmed"
      />
      <OrderConfirmed
        :orderConfirmed="sellConfirmed"
        title="trade.sale_confirmed"
      />
      <OrderConfirmed
        :orderConfirmed="limitOrderConfirmed"
        title="trade.limit_order_confirmed"
        smallTitle
      />
    </div>

    <!-- ReviewOrder -->
    <div class="text-xl33 font-bold mt-18">Review Order</div>
    <div class="flex justify-between mt-6">
      <ReviewOrder
        @onContinue="buttonClickedExample"
        @onCancel="buttonClickedExample"
        continueLabel="buy_now"
        :reviewOrder="instantBuy"
        :total="instantBuyTotal"
      />
      <ReviewOrder
        @onContinue="buttonClickedExample"
        @onCancel="buttonClickedExample"
        continueLabel="trade.set_limit_order"
        :reviewOrder="limitBuy"
        :total="limitBuyTotal"
      />
    </div>
    <div class="flex justify-between mt-6">
      <ReviewOrder
        @onContinue="buttonClickedExample"
        @onCancel="buttonClickedExample"
        continueLabel="sell_now"
        :reviewOrder="instantSell"
        :total="instantSellTotal"
      />
      <ReviewOrder
        @onContinue="buttonClickedExample"
        @onCancel="buttonClickedExample"
        continueLabel="trade.set_limit_order"
        :reviewOrder="limitSell"
        :total="limitSellTotal"
      />
    </div>

    <!-- TabSelector -->
    <div class="text-xl33 font-bold mt-18">Tab Selector</div>
    <div class="mt-6">
      <TabSelector
        :tabsList="tabsList"
        :selectedTab="selectedTab"
        :secondaryTabsList="secondaryTabsList"
        :secondarySelectedTab="secondarySelectedTab"
        size="large"
        @tabAction="changeTab"
      />
    </div>
    <div class="mt-10">
      <TabSelector
        :tabsList="tabsList"
        :selectedTab="selectedTab"
        :secondaryTabsList="secondaryTabsList"
        :secondarySelectedTab="secondarySelectedTab"
        size="medium"
        @tabAction="changeTab"
      />
    </div>

    <!-- Toggle List -->
    <div class="text-xl33 font-bold mt-16">Toggle List</div>
    <div class="flex w-full items-end justify-between">
      <div style="width: 336px">
        <ToggleList
          type="trade-preset-values"
          :headers="toggleListHeaders"
          :items="toggleListItems"
        />
      </div>
      <div style="width: 336px">
        <ToggleList
          :colStyles="[
            'font-normal text-n-md justify-start',
            'font-normal text-n-md justify-end',
            'font-normal text-n-md justify-end',
          ]"
          :items="toggleListItems"
        >
          <div slot="header" class="text-n-2xl">Custom header</div>
        </ToggleList>
      </div>
      <div style="width: 336px">
        <ToggleList :items="toggleListItems" :showRadio="false" />
      </div>
    </div>

    <div class="flex flex-col gap-s20 mt-16">
      <div class="headline-large">Open Modals</div>

      <CustomInput v-model="modalType" placeholder="Modal name" />

      Ex: <code>{"type": "deposit"}</code>
      <textarea class="border border-border p-s16" v-model="modalProps" />

      <ButtonV2
        label="Open modal"
        version="primary"
        @onClick="() => this.showModal(modalType, JSON.parse(modalProps))"
      />
      <ButtonV2
        version="tertiary"
        label="Add Credit Card"
        @onClick="showModal('AddCreditCardMethod')"
      />
    </div>

    <div class="flex flex-col gap-s20 mt-16">
      <div class="headline-large">ToastMessage</div>
      <p>
        To use the component ToastMessage, you don't need to import the
        component, just use one of the cases below, only using the function call
        as required.
      </p>
      <div class="flex justify-between">
        <ButtonV2
          version="primary"
          label="Success"
          @onClick="showSuccess('This is a success message')"
        />
        <ButtonV2
          version="destructive"
          label="Generic error"
          @onClick="showError('generic')"
        />
        <ButtonV2
          version="destructive"
          label="Custom error Message"
          @onClick="showMessage('This is an custom error message', 'error')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/misc/Button';
import ButtonV2 from '@/stories/misc/ButtonV2';
import IconSearch from '@/assets/icons/search.svg';
import { ImageResize } from '@/components/misc';
import {
  CustomInput,
  InfoPanel,
  OrderConfirmed,
  ReviewOrder,
  TabSelector,
  ToggleList,
} from '@/components/misc';
import { MODALS } from '@/mocks/app';

export default {
  name: 'Dev',
  components: {
    Button,
    ButtonV2,
    CustomInput,
    IconSearch,
    InfoPanel,
    ImageResize,
    OrderConfirmed,
    ReviewOrder,
    TabSelector,
    ToggleList,
  },

  data() {
    return {
      modalsList: MODALS,
      modalProps: '',
      modalType: '',
      buyConfirmed: [
        {
          label: 'trade.total_purchase',
          value: this.numberFormat('20', 2, false, true),
        },
        {
          label: 'trade.quantity_purchase',
          value: `~${this.numberFormat('1.46')}%`,
        },
      ],
      input: '',
      doubleInput: '',
      instantBuy: [
        {
          label: 'trade.balance',
          value: this.numberFormat('525.32', 2, false, true),
        },
        {
          label: 'trade.average_card_price',
          value: this.numberFormat('1000.0000000000000000', 2, false, true),
        },
        {
          label: 'trade.quantity_purchased',
          value: `~${this.numberFormat('0.0971000000000000')}%`,
        },
        {
          label: 'trade.purchase_amount',
          value: this.numberFormat('100.0000000000000000', 2, false, true),
        },
        {
          label: 'trade.fee',
          value: this.numberFormat('2.9000000000000000', 2, false, true),
        },
      ],
      instantSell: [
        {
          label: 'trade.average_card_price',
          value: this.numberFormat('1400.0000000000000000', 2, false, true),
        },
        {
          label: 'trade.quantity_sold',
          value: `~${this.numberFormat('0.5710000000000000')}%`,
        },
        {
          label: 'trade.sell_amount',
          value: this.numberFormat('15.9600000000000000', 2, false, true),
        },
        {
          label: 'trade.fee',
          value: this.numberFormat('0.5800000000000000', 2, false, true),
        },
      ],
      limitBuy: [
        {
          label: 'trade.balance',
          value: this.numberFormat('525.32', 2, false, true),
        },
        {
          label: 'trade.quantity_ordered',
          value: `~${this.numberFormat('0.67000000000000')}%`,
        },
        {
          label: 'trade.card_price',
          value: this.numberFormat('1480.0000000000000000', 2, false, true),
        },
        {
          label: 'trade.limit_order_amount',
          value: this.numberFormat('9.7100000000000000', 2, false, true),
        },
        {
          label: 'trade.fee',
          value: this.numberFormat('0.2900000000000000', 2, false, true),
        },
      ],
      limitOrderConfirmed: [
        {
          label: 'trade.total_order',
          value: this.numberFormat('10', 2, false, true),
        },
        {
          label: 'trade.quantity_order',
          value: `~${this.numberFormat('0.51')}%`,
        },
        {
          label: 'trade.card_price',
          value: this.numberFormat('1480', 2, false, true),
        },
      ],
      limitSell: [
        {
          label: 'trade.quantity_to_sell',
          value: `~${this.numberFormat('0.5170000000000000')}%`,
        },
        {
          label: 'trade.card_price',
          value: this.numberFormat('1480.0000000000000000', 2, false, true),
        },
        {
          label: 'trade.limit_order_amount',
          value: this.numberFormat('9.7100000000000000', 2, false, true),
        },
        {
          label: 'trade.fee',
          value: this.numberFormat('0.2900000000000000', 2, false, true),
        },
      ],
      secondarySelectedTab: '1D',
      secondaryTabsList: ['1D', '1W', '1M', 'ALL'],
      selectedTab: 'top_gainers',
      sellConfirmed: [
        {
          label: 'trade.total_sold',
          value: this.numberFormat('15.38', 2, false, true),
        },
        {
          label: 'trade.quantity_sold',
          value: `~${this.numberFormat('0.57')}%`,
        },
      ],
      tabsList: ['top_gainers', 'top_losers', 'most_volume'],
      toggleListHeaders: ['Purchase Amount', 'Card Quantity', 'Card Price'],
      toggleListItems: [
        ['$ 5.00', '0.30%', '$ 1445.00'],
        ['$ 10.00', '0.60%', '$ 1455.00'],
        ['$ 20.00', '1.20%', '$ 1495.00'],
        ['$ 50.00', '2.50%', '$ 1520.00'],
        ['$ 100.00', '5.00%', '$ 1735.00'],
      ],
    };
  },

  computed: {
    showDev() {
      return (
        process.env.NODE_ENV !== 'production' ||
        process.env.VUE_APP_ENABLE_DEV_PAGE
      );
    },

    instantBuyTotal() {
      return {
        label: 'trade.total_purchase',
        value: this.numberFormat('102.9', 2, false, true),
      };
    },

    instantSellTotal() {
      return {
        label: 'trade.total_sold',
        value: this.numberFormat('15.38', 2, false, true),
      };
    },

    limitBuyTotal() {
      return {
        label: 'trade.total_purchase',
        value: this.numberFormat('10', 2, false, true),
      };
    },

    limitSellTotal() {
      return {
        label: 'trade.total_to_sell',
        value: this.numberFormat('9.42', 2, false, true),
      };
    },
  },

  methods: {
    openDemoModal(item) {
      if(!item) { return; }
      this.showModal(item);
    },
    openFracPack() {
      this.showModal('FracPack', {
        noPadding: true,
        isClosable: false,
        pack: {
          __typename: 'Pack',
          id: '5bc15231-db72-472c-b05c-2235e5f62a63',
          img: 'https://img.dibbscdn.com/cdn-cgi/image/width=456,height=682/frac_pack_std.png',
          campaign: '5959513e-0b82-4712-803d-7a6316f949e8',
          state: 'rolled',
          time_created: '1651201650',
          time_sent: null,
          assets: [
            {
              __typename: 'PackResultAsset',
              asset: {
                __typename: 'Asset',
                id: 3,
                ticker: 'DHTCR13',
                name: '2013 Topps Chrome Refractor DeAndre Hopkins PSA 10',
                img_front:
                  'https://img.dibbscdn.com/21bbcbcf-5abb-41de-87d7-eab3e6370156',
                img_alt: '',
                price_usd: '1408147.5200000000000000',
                associated_players: [
                  { __typename: 'Player', name: 'DeAndre Hopkins' },
                ],
              },
              amount: '0.0000010700000000',
            },
            {
              __typename: 'PackResultAsset',
              asset: {
                __typename: 'Asset',
                id: 3,
                ticker: 'DHTCR13',
                name: '2013 Topps Chrome Refractor DeAndre Hopkins PSA 10',
                img_front:
                  'https://img.dibbscdn.com/21bbcbcf-5abb-41de-87d7-eab3e6370156',
                img_alt: '',
                price_usd: '1408147.5200000000000000',
                associated_players: [
                  { __typename: 'Player', name: 'DeAndre Hopkins' },
                ],
              },
              amount: '0.0000008900000000',
            },
            {
              __typename: 'PackResultAsset',
              asset: {
                __typename: 'Asset',
                id: 3,
                ticker: 'DHTCR13',
                name: '2013 Topps Chrome Refractor DeAndre Hopkins PSA 10',
                img_front:
                  'https://img.dibbscdn.com/21bbcbcf-5abb-41de-87d7-eab3e6370156',
                img_alt: '',
                price_usd: '1408147.5200000000000000',
                associated_players: [
                  { __typename: 'Player', name: 'DeAndre Hopkins' },
                ],
              },
              amount: '0.0000007100000000',
            },
            {
              __typename: 'PackResultAsset',
              asset: {
                __typename: 'Asset',
                id: 3,
                ticker: 'DHTCR13',
                name: '2013 Topps Chrome Refractor DeAndre Hopkins PSA 10',
                img_front:
                  'https://img.dibbscdn.com/21bbcbcf-5abb-41de-87d7-eab3e6370156',
                img_alt: '',
                price_usd: '1408147.5200000000000000',
                associated_players: [
                  { __typename: 'Player', name: 'DeAndre Hopkins' },
                ],
              },
              amount: '0.0000005300000000',
            },
            {
              __typename: 'PackResultAsset',
              asset: {
                __typename: 'Asset',
                id: 3,
                ticker: 'DHTCR13',
                name: '2013 Topps Chrome Refractor DeAndre Hopkins PSA 10',
                img_front:
                  'https://img.dibbscdn.com/21bbcbcf-5abb-41de-87d7-eab3e6370156',
                img_alt: '',
                price_usd: '1408147.5200000000000000',
                associated_players: [
                  { __typename: 'Player', name: 'DeAndre Hopkins' },
                ],
              },
              amount: '0.0000003600000000',
            },
          ],
          userAmountOwned: '__vue_devtool_nan__',
        },
      });
    },

    buttonClickedExample() {
      alert('Button Clicked!');
    },
    changeTab(newTab, primary = true) {
      if (primary) {
        this.selectedTab = newTab;
      } else {
        this.secondarySelectedTab = newTab;
      }
    },
  },
};
</script>

<style scoped>
.dev-page {
  @apply px-s20;
  text-align: center;
  max-width: 1300px;
  margin: 3% auto 50px auto;
}
.dev-form {
  @apply flex flex-col justify-end mx-4;
}
</style>
